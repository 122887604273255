<template>
  <div class="vod gray-bcg-color">
    <c-banner :bannerData="bannerData"
              :src="src"></c-banner>
    <c-menu :menuData="menuData"></c-menu>
    <a name="advantage"
       class="anchor-replace"></a>
    <div class="v-advantage">
      <c-title :titleArr="advantageTitle"></c-title>
      <product-advantage :advantageData="advantageData"></product-advantage>
    </div>
    <a name="solution"
       class="anchor-replace"></a>
    <div class="v-solution white_bcg-color">
      <c-title :titleArr="solutionTitle"></c-title>
      <c-solution :src="solutionSrc"></c-solution>
    </div>
    <a name="function"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="functionTitle"></c-title>
      <product-fun :productFunData="productFunData"
                   serviceType="vod"></product-fun>
    </div>
    <a name="price"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="priceTitle"></c-title>
      <c-price :priceData="priceData"></c-price>
    </div>
    <a name="doc"
       class="anchor-replace"></a>
    <div class="document">
      <c-title class="doc"
               :titleArr="documentTitle"
               :isDocTitle="true"></c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/181.html`"
           target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/187.html`"
           target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/190.html`"
           target="_blank">API</a>
        <a :href="`${docSrc}docs/show/701.html`"
           target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/704.html`"
           target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommand"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="vod"></c-recommend>
    </div>
  </div>
</template>

<script>
import {
  cRecommend,
  cBanner,
  cPrice,
  cMenu,
  cSolution,
  productFun,
  productAdvantage
} from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cRecommend,
    cBanner,
    cPrice,
    cMenu,
    cSolution,
    productFun,
    productAdvantage,
    cTitle
  },
  data () {
    return {
      advantageTitle: ['产品优势'],
      functionTitle: ['产品功能'],
      solutionTitle: ['端到端的解决方案'],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      docSrc: window.urlConfig.docUrl,
      bannerData: {
        title: '点播',
        textData: [
          '支持灵活上传、快速转码、海量存储、分发、多终端',
          '播放及控制台管理功能',
          '满足多终端设备的播放需求'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/695.html`
      },
      src: require('../../../assets/images/vod/vod-banner.png'),
      solutionSrc: require('../../../assets/images/vod/solution@2x.png'),
      advantageData: [
        {
          src: require('../../../assets/images/vod/advantage1@2x.png')
        },
        {
          src: require('../../../assets/images/vod/advantage2@2x.png')
        },
        {
          src: require('../../../assets/images/vod/advantage3@2x.png')
        },
        {
          src: require('../../../assets/images/vod/advantage4@2x.png')
        }
      ],
      priceData: [
        {
          bcg: '#00EB82',
          title: '流量计费',
          text: '1.5元／GB',
          detailUrl: `${window.urlConfig.docUrl}docs/show/183.html#22-点播流量`
        },
        {
          bcg: '#65A4FE',
          title: '存储计费',
          text: '0.02元／GB／天',
          detailUrl: `${window.urlConfig.docUrl}docs/show/183.html#21-点播存储`
        },
        {
          bcg: '#EEAF56',
          title: '转码计费',
          text: '0.08元／分钟',
          detailUrl: `${window.urlConfig.docUrl}docs/show/183.html#23-点播转码`
        }
      ],
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '功能介绍'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#solution',
          text: '解决方案'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#function',
          text: '产品功能'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav6@2x.png'),
          id: '#recommand',
          text: '相关推荐'
        }
      ],
      productFunData: [
        {
          className: 'vodupload',
          title: '点播上传',
          desc: '多终端多方式上传，上传加速，极致体验'
        },
        {
          className: 'vodwatch',
          title: '点播观看',
          desc: '多终端播放SDK，全网加速，享受多平台下的极致观看体验'
        },
        {
          className: 'vodManage',
          title: '点播管理',
          desc:
            '通过控制台或API管理视频信息，完成对视频从上传到下载播放及数据查询的一系列管理操作'
        },
        {
          className: 'vodtranscode',
          title: '视频转码',
          desc:
            '多码率音视频转码、水印方案，满足不同终端设备的播放需求，保障画质清晰观看流畅'
        },
        {
          className: 'vodsecurity',
          title: '点播安全',
          desc: '通过防盗链、播放鉴权、视频加密等多级安全机制，充分保障视频安全'
        },
        {
          className: 'vodcount',
          title: '数据统计',
          desc:
            '提供用量统计、多维度访客数据，全方位的数据统计帮助用户及时了解用量并从多个维度深入了解观众'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.vod {
  .doc {
    padding-top: 44px;
  }
  .doc-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 53px;

    a {
      color: #bfcddd;
      font-size: 18px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
